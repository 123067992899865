<template>
    <div class="cookie-dlg"
        v-if="showing">

        <div class="box">
            <div class="header">
                <h4 v-show="!setup">{{voMsg('cookie.header')}}</h4>
                <div v-show="setup">
                    <h4 v-show="setup">{{voMsg('cookie.setup')}}</h4>
                    <div @click="onCloseBtnClick"
                        class="close-setup">
                        <i class="fas fa-times"></i>
                    </div>
                </div>

            </div>
            <div class="body"
                v-show="setup">

                <div class="checkbox checkbox-disabled">
                    <input type="checkbox"
                        id="cookie_own"
                        onclick="return false;"
                        checked>
                    <label for="cookie_own">{{voMsg('cookies.own')}}</label>
                </div>
                <div class="checkbox checkbox-success"
                    v-show="VOFFICE.settings.mapType !== 'openstreet'">
                    <input type="checkbox"
                        id="googlemaps"
                        v-model="showGoogleMaps">
                    <label for="googlemaps">{{voMsg('cookies.googlemaps')}}</label>
                </div>
                <div class="checkbox checkbox-success">
                    <input type="checkbox"
                        id="cookie_others"
                        v-model="cookiesOk">
                    <label for="cookie_others">{{voMsg('cookies.others')}}</label>
                </div>


            </div>
            <div class="body"
                v-show="!setup">
                <div>
                    Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrungen zu verbessern. Sie können die Verwendung von Cookies über die Einstellungen anpassen.
                    <br/>
                    <br/> Indem Sie auf den Button „Akzeptieren“ klicken werden alle Kategorien von Cookies aktiviert. Weitere Informationen finden Sie in unserer
                    <voffice-doc-box doc-id="privacy">
                        <a class="privacy-link"
                            href="#">Datenschutzerklärung</a>
                    </voffice-doc-box> und dem <a href="./impressum"
                        target="_blank">Impressum</a>.
                </div>


            </div>


            <div class="footer"
                v-show="setup">

                <button type="button"
                    @click="onSaveBtnClick"
                    class="btn btn-primary">{{voMsg('cookies.saveselection')}}</button>

            </div>
            <div class="footer"
                v-show="!setup">

                <div class="text-right">
                    <button type="button"
                        @click="onSaveBtnClickEssentiell"
                        class="btn btn-default btn-sm">{{voMsg('cookies.onlynecessary')}}</button>
                </div>

                <div class="text-right mt-2">
                    <button type="button"
                        @click="onSetupBtnClick"
                        class="btn btn-default btn-sm">{{voMsg('cookies.setup')}}</button>
                    <button type="button"
                        @click="onOkBtnClick"
                        class="btn btn-primary btn-sm">{{voMsg('cookies.ok')}}</button>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import V from 'voUtils/V.js'
    import {
    	saveSelection, hasCookieChoice, isCookiesOk
    }
    from 'voUtils/TagEx.js'

    const USE_GOOGLE_MAPS = (VOFFICE.settings.mapType);

    export default {
    	voVueComponent: 'voffice-cookie-box',
    	props: {},
    	data: function() {
    		var GoogleMaps = localStorage.getItem("allowGoogleMaps");
    		if (GoogleMaps === null) {
    			var showGoogleMaps = false;
    		} else if (GoogleMaps === 'false') {
    			var showGoogleMaps = false;
    		} else if (GoogleMaps === 'true') {
    			var showGoogleMaps = true;
    		}

    		return {
    			showing: !hasCookieChoice(),
    			setup: false,
    			cookiesOk: isCookiesOk(),
    			showGoogleMaps: showGoogleMaps


    		}
    	},

    	computed: {
    		introMsg: function() {
    			return this.voMsg('cookies.intro').split('\n').join('<br>')
    		}

    	},
    	methods: {

    		showCookieSettings: function() {
    			this.showing = true;
    			this.setup = true;
    			this.cookiesOk = isCookiesOk();
    			var GoogleMaps = localStorage.getItem("allowGoogleMaps");
    			if (GoogleMaps === null) {
    				this.showGoogleMaps = false;
    			} else if (GoogleMaps === 'false') {
    				this.showGoogleMaps = false;
    			} else if (GoogleMaps === 'true') {
    				this.showGoogleMaps = true;
    			}
    		},

    		onSetupBtnClick: function() {
    			this.setup = true;
    		},
    		onSaveBtnClick: function() {
    			if ($('#googlemaps').is(':checked')) {
    				localStorage.setItem("allowGoogleMaps", true);
    			} else {
    				localStorage.setItem("allowGoogleMaps", false);
    			}
    			saveSelection(this.cookiesOk);
    			this.showing = false;
    			location.reload();

    		},
    		onSaveBtnClickEssentiell: function() {
    			saveSelection(false);
    			localStorage.setItem("allowGoogleMaps", false);
    			this.showing = false;

    		},
    		onOkBtnClick: function() {
    			saveSelection(true);
    			localStorage.setItem("allowGoogleMaps", true);
    			this.showing = false;
    			location.reload();
    		},

    		onCloseBtnClick: function() {
    			this.showing = false;
    		}

    	}

    };
</script>

<style scoped>
    .cookie-dlg {
    	padding: 20px;
    	z-index: 990;
    	background: rgba(0, 0, 0, 0.5);

    	position: fixed !important;
    	left: 0px !important;
    	right: 0px !important;
    	bottom: 0px !important;
    	top: 0px !important;
    	margin: 0px !important;

    	.privacy-link {
    		color: inherit;
    		text-decoration: underline;
    	}

    }

    .box {
    	position: relative;
    	width: 600px;

    	background: #ffffff;
    	border: solid 1px #cccccc;
    	border-radius: 8px;
    	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

    	left: auto;
    	margin-right: auto;
    	margin-left: auto;
    }

    .body {
    	padding: 22px 30px;
    	overflow: auto;
    	position: relative;
    	-webkit-overflow-scrolling: touch;
    	font-size: 14px;

    }


    .footer {
    	border-top: 1px solid rgb(235, 235, 235);
    	padding: 12px 15px;
    }

    .header {
    	border-bottom: 1px solid rgb(235, 235, 235);
    	padding: 6px 15px;
    	text-align: center;
    }

    .cookie-dlg.show {
    	display: block;
    }

    .checkbox label {
    	width: 100%;
    }

    .close-setup {
    	position: absolute;
    	right: 10px;
    	color: #000;
    	font-size: 22px;
    	top: 0px;
    	cursor: pointer;
    }

    .fas {
    	color: #666 !important;
    }


    @media (max-width:767px) {

    	.cookie-dlg {
    		padding: 0;
    		top: auto !important;

    	}

    	.box {
    		width: 100%;
    		height: 100%;
    		border-radius: 0px;
    		border: none;

    	}



    }
</style>